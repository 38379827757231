.backdrop {
  @apply fixed top-0 left-0 w-full h-screen z-[99999] bg-zinc-900/70;
}
.modal {
  @apply fixed z-[999999] overflow-hidden bg-white rounded-md w-max top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2;
}
.header {
  @apply px-6 py-3 flex justify-end bg-gradient-to-l from-pult to-pult-dark max-w-[80vw];
}
.header h2 {
  @apply text-white flex-auto text-lg my-auto mr-6 whitespace-normal;
}
.content {
  @apply px-3 py-1 max-h-[80vh] max-w-[80vw] overflow-y-auto;
}
.content::-webkit-scrollbar {
  @apply w-1;
}
.content::-webkit-scrollbar-track {
  @apply rounded-full bg-transparent
  transition-all duration-300 ease-in-out;
}
.content::-webkit-scrollbar-thumb {
  @apply bg-pult/40 rounded-full;
}
.content::-webkit-scrollbar-thumb:hover {
  @apply bg-pult/70;
}
.submit {
  @apply flex justify-items-center w-max mx-auto;
}
.items {
  @apply gap-0 my-0;
}
.titles {
  @apply text-center bg-pult/10 px-2 rounded-t-md;
}
.rows {
  @apply my-1 px-2;
}
.content .rows:nth-child(2n) {
  @apply bg-pult/5;
}
.labels {
  @apply my-auto text-sm;
}
.number {
  @apply text-center;
}
.unit {
  @apply text-xs ml-1.5 my-auto;
}
.shortInput {
  @apply w-1/2 mx-auto block my-0.5 !important;
}
.formFooter div,
.formFooter button {
  @apply mx-auto;
}
.items {
  @apply py-0.5 px-1.5 m-0.5 border border-pult/10 rounded-md bg-white whitespace-nowrap h-max my-auto;
}
