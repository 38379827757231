.section {
  @apply grow flex justify-center overflow-y-auto;
}
.section::-webkit-scrollbar {
  @apply w-2;
}
.section::-webkit-scrollbar-track {
  @apply rounded-[100vh] bg-transparent;
}
.section::-webkit-scrollbar-thumb {
  @apply bg-pult-hover rounded-[100vh];
}
.section::-webkit-scrollbar-thumb:hover {
  @apply bg-pult-selected;
}
.container {
  @apply w-max h-max p-3 my-auto bg-zinc-100 rounded-md shadow-xl shadow-zinc-300 text-center block border border-zinc-300;
}
.container h2 {
  @apply mb-2;
}
