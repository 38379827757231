.field {
  @apply my-1 md:my-2;
}
.field label {
  @apply block text-mm text-left text-zinc-400;
}
.field input,
.input {
  @apply border border-pult-selected rounded-md w-full px-2 py-1;
}
.field select {
  @apply border border-pult-selected rounded-md w-full px-2 py-1.5 bg-white;
}
.field textarea {
  @apply border border-pult-selected rounded-md w-full px-2 py-1.5 bg-white;
}
.password {
  @apply my-1 md:my-2 relative;
}
.password input {
  @apply pr-8;
}
.field input:disabled {
  @apply text-pult bg-zinc-200 cursor-not-allowed;
}
.invalid input,
.invalid select,
.invalid textarea,
.invalid-input {
  @apply border-red-600 bg-red-100;
}
.invalid label,
.invalid-label {
  @apply text-red-600 !important;
}
.show-password {
  @apply absolute bottom-1 right-1 cursor-pointer;
}
.checkbox {
  @apply border border-pult rounded-md py-1.5 px-3 w-full my-1 text-center cursor-pointer;
}
.max {
  @apply w-max !important;
}
.checkbox input {
  @apply hidden;
}
.checked {
  @apply bg-pult text-white;
}
.work {
  @apply border border-pult-selected rounded-md px-1.5 py-0.5;
}
.field-file {
  @apply my-1 md:my-2;
}
.field-file label {
  @apply block text-mm text-left text-zinc-400;
}
.field-file input {
  @apply border border-pult-selected rounded-md w-full text-sm text-gray-500 cursor-pointer
    file:mr-4 file:py-2 file:px-4 file:rounded-md file:border-0 file:text-sm file:font-semibold file:bg-pult file:text-white;
}
.range {
  @apply border border-pult-selected rounded-md w-16 px-2 py-1;
}
.file {
  @apply border border-pult-selected rounded-md cursor-pointer
    bg-gray-300 text-gray-800 hover:bg-gray-400;
}
.options {
  @apply border border-pult-selected rounded-md w-full px-1 py-0.5 lg:px-2 lg:py-1;
}
.job {
  @apply border border-pult-selected rounded-md px-1.5 py-0.5 w-24;
}

.select {
  white-space: pre-wrap; /* Сохранение пробелов и обработка переносов строк */
}

.select option {
  white-space: pre-wrap; /* Сохранение пробелов и обработка переносов строк */
  word-wrap: break-word; /* Перенос длинных слов для соответствия строке */
  padding: 5px; /* Добавление отступов для лучшей читаемости */
  display: block;
}

/* Дополнительно: Дополнительные стили для улучшения внешнего вида */
.select option:before {
  content: attr(data-content);
  white-space: pre-wrap; /* Обработка многострочности */
}
