.container {
  max-height: calc(100vh - 100px);
  @apply overflow-auto rounded-md shadow-lg shadow-pult/40 mb-3;
}
.container::-webkit-scrollbar {
  @apply w-1.5 h-1.5;
}
.container::-webkit-scrollbar-track {
  @apply rounded-[100vw] bg-zinc-200;
}
.container::-webkit-scrollbar-thumb {
  @apply bg-zinc-500 rounded-[100vw];
}
.container::-webkit-scrollbar-thumb:hover {
  @apply bg-zinc-600;
}

.table {
  @apply w-full table-auto mb-0.5;
}
.container thead,
.thead {
  @apply sticky -top-0.5 z-10 bg-pult text-white font-normal;
}
.container th {
  @apply font-normal;
}
.container th,
.table td {
  @apply px-2 py-0.5 border border-pult/40;
}
.container tr:nth-child(2n) {
  @apply bg-pult/10;
}
.name {

}
.period-name {
  @apply first-letter:uppercase font-normal px-2 py-1 whitespace-pre-line;
}
.price {
  @apply break-all text-center;
}
.invalid {
  @apply bg-red-100 !important;
}
.group {
  @apply bg-teal-800 text-white !important;
}
.sub-group {
  @apply bg-lime-300 !important;
}
.sub-sub-group {
  @apply bg-amber-100 !important;
}
.selected {
  @apply bg-pult-hover/50 text-pult/60 !important;
}
