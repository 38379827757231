.card {
  @apply gap-4 rounded-md border border-pult/20 shadow shadow-pult/30 bg-pult/5 flex flex-col overflow-auto m-2;
}

.link {
  @apply block w-1/2 text-center mx-auto bg-pult hover:bg-pult-hover text-white p-1 rounded shadow shadow-pult/20 hover:shadow-pult/50
    transition-all duration-300 ease-in-out;
}

.declined {
  @apply bg-warning/5;
}
.label {
  @apply px-1.5 bg-pult text-white text-center py-1 border border-pult;
}
.declined .label {
  @apply bg-warning/50 text-black border-warning;
}
.area {
  @apply p-2 grow;
}
.card .area .data {
  @apply rounded bg-white my-0.5 p-1 text-sm;
}
.list {
  @apply flex flex-wrap;
}
.list .card,
.list .btn {
  @apply m-1;
}
.days {
  @apply m-0.5;
}
.editDays {
  @apply bg-white rounded-md border border-pult/20 shadow shadow-pult/30 cursor-pointer;
}
.items {
  @apply py-0.5 px-1.5 m-0.5 border border-pult/10 rounded-md bg-white h-max my-auto;
}
.personName {
  @apply text-center my-1 font-bold;
}
.rowName {
  @apply text-xs;
}
.properties {
  @apply flex gap-2 flex-wrap;
}
.properties li {
  @apply border border-pult/20 rounded-md p-2;
}
.orders {
  @apply ml-2 my-2;
}
.orders h3 {
  @apply flex items-center gap-2;
}
.orders h3 svg {
  @apply h-6;
}
