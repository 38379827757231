.container {
  @apply flex h-[54px] w-full;
}

.collapsed {
  @apply flex justify-center w-full;
}

.dropdown {
  @apply bg-pult-button p-2 rounded shadow shadow-black;
  @apply text-white duration-300 ease-in-out w-full;
}

.dropdown-show {
  @apply scale-y-100 duration-300 ease-in-out w-full;
}

.dropdown option {
  @apply text-sm w-full;
}

.dropdown .selected {
  @apply bg-pult-selected w-full;
}
