.status {
  @apply flex w-max mx-auto;
}
.status svg {
  @apply w-6 h-6 mr-2 flex-none my-auto;
}
.green {
  @apply fill-green-500;
}
.red {
  @apply fill-red-500;
}
.warning {
  @apply fill-amber-600 text-amber-700;
}
.row-name {
  @apply text-2xl whitespace-nowrap;
}
.message {
  @apply bg-amber-600 p-2 border my-3 rounded-md flex;
}
.message svg {
  @apply w-5 mr-2 my-auto;
}
.message span {
  @apply my-auto;
}
