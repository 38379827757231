.section {
  @apply grow px-1 md:px-3 py-0.5 md:py-2;
}
.section h1 {
  @apply text-lg md:text-xl;
}

.price {
  @apply text-lg text-red-800 font-bold;
}
