.member {
  @apply px-4 mb-4;
}
.member .user-name {
  @apply text-xl break-words block truncate;
}
.member .user-mail {
  @apply text-xs;
}
.member .logout {
  @apply my-0.5 md:my-2;
}
.logout .expanded-logout button {
  @apply bg-white text-pult w-full p-0.5 md:p-1 rounded
    transition-all duration-300 ease-in-out;
}
.logout .expanded-logout button:hover {
  @apply bg-pult-hover text-white
    transition-all duration-300 ease-in-out;
}

.collapsed {
  @apply px-0 mb-2;
}
.collapsed .user-name,
.collapsed .user-mail {
  @apply hidden;
}
.collapsed .logout {
  @apply pl-1 flex justify-center
    transition-all duration-500 ease-in-out;
}
.collapsed .collapsed-logout button {
  @apply w-9 h-9 md:w-10 md:h-10 xl:w-12 xl:h-12 p-2 bg-pult-button mr-1 rounded-full cursor-pointer;
}
.collapsed .collapsed-logout button:hover {
  @apply bg-pult-hover shadow-md shadow-white
    transition-all duration-300 ease-in-out;
}
