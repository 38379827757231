.header {
  @apply flex my-2;
}
.logo {
  @apply grow;
}
.logo svg {
  @apply inline-block;
}
.logo span {
  @apply ml-3 my-auto;
}
.btn {
  @apply w-max rounded-md py-1 px-2 block h-max my-auto mx-2 bg-pult-button text-white hover:bg-pult-hover cursor-pointer
    transition-all duration-300 ease-in-out;
}
