.grid-1 {
  @apply block my-4 md:grid;
}
.grid-2 {
  @apply block my-4 md:grid md:grid-cols-2 md:gap-2;
}
.grid-3 {
  @apply block md:grid md:grid-cols-2 lg:grid-cols-3 md:gap-2 my-4;
}
.grid-4 {
  @apply block md:grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 md:gap-2 my-4;
}
.grid-5 {
  @apply block md:grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 md:gap-2;
}
.grid-auto {
  @apply w-full justify-items-center grid gap-1 grid-cols-1 md:w-max md:gap-2 md:grid-cols-2 lg:grid-flow-col lg:auto-rows-max;
}
