.header {
  @apply bg-pult/10 text-center rounded-t cursor-pointer;
}
.header svg {
  @apply h-6 w-6 stroke-pult inline stroke-2 ml-2
    transition-all duration-300 ease-in-out;
}
.shown svg {
  @apply rotate-180
    transition-all duration-300 ease-in-out;
}
.name {
  @apply text-center font-bold mt-2;
}
.link {
  @apply block w-1/2 text-center mx-auto bg-pult hover:bg-pult-hover text-white p-1 shadow shadow-pult/20 hover:shadow-pult/50
    transition-all duration-300 ease-in-out;
}
.end {
  @apply border-b border-pult/10 mt-2;
}
.row {
  @apply px-2;
}
.row div:not(.name) {
  @apply border-b border-pult/20 border-dashed;
}
