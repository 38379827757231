.container {
  @apply px-4 mb-2 md:mb-4 flex;
}
.container svg {
  @apply w-6 h-6 mt-1;
}
.container select {
  @apply bg-pult-button ml-3 px-2 py-0.5 md:py-1 rounded-md w-full;
}

.collapsed {
  @apply px-0 mb-0.5 md:mb-1 xl:mb-2 flex justify-center;
}
.collapsed .icon {
  @apply bg-pult-button rounded-full cursor-pointer p-1
    transition-all duration-300 ease-in-out;
}
.collapsed .icon svg {
  @apply w-6 h-6 p-0.5;
}
.collapsed .icon:hover {
  @apply bg-pult-hover shadow-md shadow-white
    transition-all duration-300 ease-in-out;
}
.dropdown {
  @apply w-max bg-pult-button left-11 p-2 fixed rounded shadow shadow-black scale-y-0
    translate-x-4 duration-300 ease-in-out;
}
.dropdown-show {
  @apply scale-y-100
    transition-all duration-300 ease-in-out;
}
.dropdown li {
  @apply mb-1 cursor-pointer;
}
.dropdown li:hover {
  @apply bg-pult-hover;
}
.dropdown .selected {
  @apply bg-pult-selected;
}
