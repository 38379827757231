.item {
  @apply my-1 relative
    transition-all duration-500 ease-in-out;
}
.item a {
  @apply block px-4 py-1 border-none
    transition-all duration-300 ease-in-out;
}
.item {
  animation: shoItems 0.5s linear;
}
@keyframes shoItems {
  0% {
    @apply -left-[350%];
  }
  100% {
    @apply left-0;
  }
}
.item ul a {
}
.item a:hover {
  @apply bg-pult-hover
    transition-all duration-300 ease-in-out;
}
.item-list {
  @apply cursor-pointer w-full flex;
}
.item-list:hover {
  @apply bg-pult-hover
    transition-all duration-300 ease-in-out;
}
.list-amount {
  @apply w-7 pb-0.5 flex-none my-auto align-middle text-center rounded-full font-bold bg-warning text-pult;
}
.item-list svg {
  @apply mx-2 w-3 rotate-0 flex-none my-auto
    transition-all duration-500 ease-in-out;
}
.additional-list {
  @apply scale-y-0 h-0 origin-top;
}
.additional-list-show {
  @apply scale-y-100 h-max origin-top
    transition-all duration-500 ease-in-out;
}
.list-show-items {
  @apply bg-pult-hover
    transition-all duration-500 ease-in-out;
}
.list-show-items svg {
  @apply rotate-180
    transition-all duration-500 ease-in-out;
}

.collapsed-item {
  @apply my-1 flex justify-center;
}
.collapsed-item a {
  @apply w-8 text-center my-0.5 rounded-lg border border-pult-hover
    transition-all duration-300 ease-in-out;
}
.collapsed-item a:hover {
  @apply bg-pult-hover
    transition-all duration-300 ease-in-out;
}
.collapsed-item .item-list-name {
  @apply w-8 text-center my-0.5 rounded-lg border border-pult-hover cursor-pointer;
}
.collapsed-item .item-list-name:hover {
  @apply bg-pult-hover
    transition-all duration-300 ease-in-out;
}
.dropdown {
  @apply w-max bg-pult-button left-11 p-2 fixed rounded shadow shadow-black scale-y-0
    translate-x-4 duration-300 ease-in-out;
}
.dropdown-show {
  @apply scale-y-100 origin-top
    transition-all duration-300 ease-in-out;
}
.dropdown .selected {
  @apply bg-pult-selected;
}
.collapsed-list-amount {
  @apply relative font-bold bg-warning text-pult text-sm w-5 h-5 text-center rounded-full -mr-5 right-3 -mt-1;
}
.notification {
  @apply absolute left-14 py-1 w-max bg-pult rounded-md;
}
.notification a {
  @apply w-full border-0 text-left;
}
