.btn {
  @apply !rounded-none px-2 py-1 my-1.5 shadow-md shadow-black/30 w-fit cursor-pointer h-max bg-pult/10 hover:bg-transparent border-2 border-transparent
    transition-all duration-300 ease-in-out;
}
.full {
  @apply w-full !important;
}
.center {
  @apply w-max mx-auto !important;
}
.btn svg {
  @apply w-6 h-6 my-auto mr-1
    transition-all duration-300 ease-in-out;
}
.icon-only {
  @apply rounded-full p-1 w-max h-max;
}
.icon-only svg {
  @apply w-6 h-6 mx-auto;
}
.icon-only svg,
.icon:hover svg {
  @apply transition-all duration-300 ease-in-out;
}
.icon {
  @apply rounded-full flex pl-1 pr-3 whitespace-nowrap;
}
.stroke svg {
  @apply stroke-[1.5] stroke-white fill-none bg-none;
}

.link {
  @apply bg-[#3366BB]/5 text-[#3366BB] border border-[#3366BB] hover:text-[#663366]/80 hover:border-[#663366] whitespace-pre-wrap;
}

/*colors*/
.button {
  @apply bg-button text-white
    hover:bg-button-hover
    transition-all duration-300 ease-in-out;
}
.button svg {
  @apply rounded-full bg-pult;
}
/*.button:hover svg {*/
/*    @apply bg-white*/
/*}*/
.button.stroke svg {
  @apply rounded-full scale-110 p-0.5;
}
.red {
  @apply border-red-800
    hover:border-red-600 hover:bg-red-100;
}
.red svg {
  @apply fill-red-800/40
    transition-all duration-300 ease-in-out;
}
.red:hover svg {
  @apply fill-red-600
    transition-all duration-300 ease-in-out;
}
.warning {
  @apply bg-warning text-black
    hover:bg-warning/80
    transition-all duration-300 ease-in-out;
}
.disable {
  @apply bg-zinc-300 text-zinc-500 cursor-not-allowed;
}
.white {
  @apply bg-zinc-200 hover:bg-white text-pult;
}
.green {
  @apply bg-lime-700 text-white hover:bg-lime-600;
}
.apply {
  @apply border-lime-800 bg-lime-100 hover:bg-lime-300/50 transition-all duration-300 ease-in-out;
}
.apply svg {
  @apply fill-lime-800/60;
}
.apply:hover svg {
  @apply fill-lime-800/80;
}

.transparent {
  @apply border-pult-button/20 bg-transparent cursor-default shadow-none;
}
