.navbar {
  @apply w-72 text-white rounded-r-md shadow-md shadow-black flex flex-col bg-gradient-to-t from-pult to-pult-dark mr-2 flex-none z-[100]
    transition-all duration-500 ease-in-out;
}
.collapsed {
  @apply w-14
    transition-all duration-500 ease-in-out;
}
.nav-but-menu {
  @apply flex justify-between mt-0.5 md:mt-2 mb-2;
}
.collapsed .nav-but-menu {
  @apply justify-center;
}
.project {
  @apply grow overflow-y-auto;
}
.project::-webkit-scrollbar {
  @apply w-1.5 h-1.5;
}
.project::-webkit-scrollbar-track {
  @apply rounded-[100vw] bg-zinc-200;
}
.project::-webkit-scrollbar-thumb {
  @apply bg-zinc-500 rounded-[100vw];
}
.project::-webkit-scrollbar-thumb:hover {
  @apply bg-zinc-600;
}
