.layout {
  @apply p-2 grow w-5 h-screen;
}

.layout h1 {
  @apply text-sm md:text-xl lg:text-2xl mb-3 text-ellipsis;
}

.page {
  @apply p-4 h-full border border-gray-300 rounded-xl;
}

.workflow {
  @apply flex-col flex overflow-y-auto overflow-x-hidden h-full;
}

.workflow h1 {
  @apply text-base md:text-2xl mb-3 text-ellipsis;
}

.workflow h2 {
  @apply text-base md:text-xl;
}
