.nav {
  @apply bg-gradient-to-r from-pult to-pult-dark text-white flex px-1 py-0.5 md:px-3 md:py-1.5 rounded-l-lg shadow-pult/30 shadow-md w-max h-max absolute right-0 top-1 z-10 flex-wrap;
}
.select {
  @apply flex my-auto mx-1;
}
.select label {
  @apply mr-0.5 md:mr-2 my-auto text-sm hidden lg:block;
}
.select select {
  @apply border border-white/30 bg-pult-button w-full px-1 py-0.5 lg:px-2 lg:py-1;
}
.btn {
  @apply bg-pult-button cursor-pointer p-1 border border-white/30 flex
    transition-all duration-300 ease-in-out;
}
.btn svg {
  @apply w-4 h-4 lg:w-5 lg:h-5 my-auto !important;
}
.btn span {
  @apply my-auto ml-2 text-sm hidden lg:block;
}
.btn:hover {
  @apply bg-pult-hover shadow-md shadow-white
    transition-all duration-300 ease-in-out;
}
