.body {
  @apply flex gap-2 border px-1.5 py-0.5 rounded-md mb-2 items-center;
}
.body svg {
  @apply rounded-full w-6 h-6 p-0.5 flex-shrink-0 shadow shadow-black/40;
}
.warning {
  @apply text-amber-800 border-warning bg-warning/10;
}
.icon svg {
  @apply bg-warning stroke-pult;
}
.correct {
  @apply text-lime-800 border-lime-800 bg-lime-800/10;
}
.correct .icon svg {
  @apply bg-lime-100 fill-lime-700 shadow-lime-700/70 stroke-lime-200;
}
.reject {
  @apply text-red-800 border-red-800 bg-red-800/10;
}
.reject .icon svg {
  @apply bg-red-100 fill-red-700 shadow-red-700/70;
}
.info {
  @apply text-pult border-pult bg-pult/5;
}
.info .icon svg {
  @apply bg-pult-hover/10 fill-pult shadow-pult/70;
}
.max {
  @apply w-max;
}
