.popup {
  @apply bg-white border border-gray-300 shadow-md rounded-xl overflow-hidden;
}
.popup header {
  @apply py-2 pl-4 pr-2 bg-pult text-white w-full flex gap-2;
}
.popup header h2 {
  @apply w-full;
}
.close {
  @apply cursor-pointer place-self-end rounded-full bg-white my-auto;
}
.close svg {
  @apply w-6 h-6 fill-pult;
}
.content {
  @apply px-4 py-2 w-[250px] md:w-[350px] max-h-[70vh] overflow-y-auto overflow-x-hidden;
}
