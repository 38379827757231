.button {
  @apply md:inline-block mx-1 my-2 md:my-auto text-sm md:text-base text-white bg-pult px-1 py-0.5 md:px-2 md:py-1 shadow shadow-black text-center cursor-pointer
    transition-all duration-300 ease-in-out;
}

.button:hover,
.button:active {
  @apply bg-pult-hover
    transition-all duration-300 ease-in-out;
}

button:disabled,
button:focus:disabled,
button:hover:disabled,
button:active:disabled {
  @apply bg-pult-selected text-pult cursor-not-allowed;
}

.link {
  @apply w-max py-1 px-2 block h-max my-auto mx-2 bg-[#0f1937] text-white
    transition-all duration-300 ease-in-out;
}
.link:hover {
  @apply opacity-50 text-white
    transition-all duration-300 ease-in-out;
}
.margin-X-1 {
  @apply mx-1 !important;
}
.margin-X-2 {
  @apply mx-2 !important;
}
.btn {
  @apply px-2 py-1 my-1.5 shadow-md shadow-black/30 rounded-md w-fit cursor-pointer bg-pult text-white
    transition-all duration-300 ease-in-out;
}
.btn svg {
  @apply w-6 h-6 my-auto mr-1
    transition-all duration-300 ease-in-out;
}
.btn:hover {
  @apply transition-all duration-300 ease-in-out;
}
.btn:hover svg {
  @apply fill-black/40
    transition-all duration-300 ease-in-out;
}

.center {
  @apply mx-auto block !important;
}
