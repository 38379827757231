.plan {
  @apply w-full h-full overflow-hidden;
}
.ol-overviewmap-box {
  @apply border-2 border-red-500;
}
.ol-overviewmap .ol-overviewmap-map {
  @apply md:w-[250px] md:h-[150px] bg-pult-hover/80;
}
.ol-control button {
  @apply bg-pult text-white hover:text-white/80;
}
.ol-zoomslider {
  @apply bg-pult/30;
}
.ol-full-screen {
  @apply right-0 left-9 w-max;
}
